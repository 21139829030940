import { Link } from "react-router-dom";

const Login = (props) => {

    return (
        <div class="bg-primary flex flex-col lg:flex-row items-center justify-evenly py-14 rounded-3xl">

            <div class="text-center lg:text-left mb-10 lg:mb-0">
                <h1 class="font-semibold text-white text-4xl md:text-5xl lg:text-7xl leading-normal mb-4">Login to your <br /> account</h1>

                <p class="font-normal text-white text-md md:text-xl">Don't have an account yet? Not to worry! <br /> You can create a new account for free <Link to='/register' className="text-accent font-bold">HERE</Link> <br /> and start your lifefulness journey now.</p>
            </div>

            <div class="md:block bg-white xl:relative px-6 py-3 rounded-3xl">
                <div class="py-3">
                    <h3 class="font-semibold text-gray-900 text-3xl">Login</h3>
                </div>

                <div class="py-3">
                    <input type="text" placeholder="Email" class="px-4 py-4 md:w-96 sm:w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" />
                </div>

                <div class="py-3">
                    <input type="password" placeholder="Password" class="px-4 py-4 md:w-96 sm:w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" />
                </div>

                <div class="py-3">
                    <button class="w-full py-4 font-semibold text-lg bg-primary text-stone-50 hover:text-stone-100 hover:bg-gray-700 rounded-xl transition ease-in-out duration-500">Login</button>
                </div>
            </div>

        </div>
    );
}

export default Login;