import Header from './Header';
import './App.css';
import { Outlet } from "react-router-dom";
import ApplicationVersion from './ApplicationVersion';

function App() {
  return (
    <div className="App">
      <body class="bg-gray-100" style={{ minHeight: "100vh" }}>

        <section class="py-10 md:py-16">
          <Header />
          <div class="container max-w-screen-xl mx-auto px-4">
            <Outlet />
          </div>
        </section>

        <ApplicationVersion />
      </body>
    </div>
  );
}

export default App;
