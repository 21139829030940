import PetalsLifulness from './assets/petals_lifulness.png';

const Home = (props) => {
    
    return (
        <div class="text-center">
            <div class="flex justify-center mb-16">
                <img src={PetalsLifulness} alt="Image" width={300}/>
            </div>

            <h6 class="font-medium text-gray-600 text-lg md:text-2xl uppercase mb-8">Cecilia La Coppola</h6>

            <h1 class="font-normal text-gray-900 text-4xl md:text-7xl leading-none mb-8">Your Lifefulness Coach</h1>

            <p class="font-normal text-gray-600 text-md md:text-xl mb-16">Start your lifefulness and wellness journey with me now! </p>

            <a href="#" class="px-7 py-3 md:px-9 md:py-4 font-medium md:font-semibold bg-gray-700 text-gray-50 text-sm rounded-md hover:bg-gray-50 hover:text-gray-700 transition ease-linear duration-500">Start my jouney</a>
        </div>
    );
}

export default Home;