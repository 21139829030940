import { Link } from "react-router-dom";

const Register = (props) => {

    return (
        <div class="bg-secondary flex flex-col lg:flex-row items-center justify-evenly py-14 rounded-3xl">

            <div class="md:block bg-white xl:relative  px-6 py-3 rounded-3xl sm:order-last lg:order-first">
                <div class="py-3">
                    <h3 class="font-semibold text-gray-900 text-3xl">Register</h3>
                </div>

                <div class="py-3">
                    <input type="text" placeholder="Email" class="px-4 py-4 md:w-96 sm:w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" />
                </div>

                <div class="py-3">
                    <input type="password" placeholder="Password" class="px-4 py-4 md:w-96 sm:w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" />
                </div>

                <div class="py-3">
                    <input type="password" placeholder="Password Confirmation" class="px-4 py-4 md:w-96 sm:w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" />
                </div>

                <div class="py-3">
                    <button class="w-full py-4 font-semibold text-lg bg-secondary text-gray-700 hover:text-stone-100 hover:bg-gray-700 rounded-xl transition ease-in-out duration-500">Register</button>
                </div>
            </div>

            <div class="text-center lg:text-left mb-10  lg:mb-0">
                <h1 class="font-semibold text-white text-4xl md:text-5xl lg:text-7xl leading-normal mb-4">Register your <br /> account</h1>

                <p class="font-normal text-white text-md md:text-xl">Already have an account? <br /> Login to your account <Link to='/login' className="text-accent font-bold">HERE</Link></p>
            </div>

        </div>
    );
}

export default Register;