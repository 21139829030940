
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from './assets/logo.svg';

const Header = (props) => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        setIsNavOpen(false);
    }, [window.location.pathname]);

    let operationalEnvionment = process.env.REACT_APP_ENV;
    let operationalEnvionmentClass = null;

    switch (operationalEnvionment) {
        case "PROD":
            operationalEnvionmentClass = "text-red-700";
            break;
        case "DEV":
        case null:
        case undefined:
            operationalEnvionmentClass = "text-green-700"
            operationalEnvionment = "DEV"
            break
        case "SIT":
        case "UAT":
            operationalEnvionmentClass = "text-amber-700"
            break;
        default:
            operationalEnvionment = "operationalEnvironment is broken"
            operationalEnvionmentClass = "text-red-700";
            break;
    }

    const isTestSystem = ["DEV", "SIT"].includes(operationalEnvionment)
    return (
        <div class="container max-w-screen-xl mx-auto px-4">

            <nav class="flex items-center justify-between mb-20">
                <div className="flex items-end gap-2">
                    <img src={logo} alt="Logo" width={100} />
                    <p className={`font-bold ${operationalEnvionmentClass}`}>
                        {operationalEnvionment}
                    </p>
                </div>

                <div>
                    <Link to="/login" class="px-7 py-3 mx-5 md:px-9 md:py-4 bg-primary text-stone-50 hover:text-stone-100 hover:bg-gray-700 font-medium md:font-semibold text-md rounded-md transition ease-linear duration-500">Login</Link>
                    <Link to="/register" class="px-7 py-3 mx-5 md:px-9 md:py-4 bg-primary text-stone-50 hover:text-stone-100 hover:bg-gray-700 font-medium md:font-semibold text-md rounded-md transition ease-linear duration-500">Register</Link>
                </div>
            </nav>
        </div>
    );
}

export default Header;